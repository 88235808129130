import CallApp from '@shared/util/callApp/index'
import { USER_ID_KEY } from '@shared/util/request'

export const WXYKT_APP_CALL_SETTING = Object.freeze({
  scheme: {
    protocol: 'wangxiaoyunxuetang',
  },
  intent: {
    package: 'edu.www.wxyxt',
    scheme: 'wangxiaoyunxuetang',
  },
  universal: {
    host: 'appwxyxt.qsxt.info',
    pathKey: '',
  },
  appstore: 'itms-apps://itunes.apple.com/app/id1671747646',
  yingyongbao: '//a.app.qq.com/o/simple.jsp?pkgname=edu.www.wxyxt',
  fallback: `${import.meta.env.VITE_SITE_URL}/mall/mobile/custom/app`,
  timeout: 2000,
})

let wxyxt: CallApp
interface CallWxyxtAppParams {
  organizationId?: string
  schoolId?: string
  classId?: string
  userId?: string
}
export const callWxyxtApp = ({ path, param } = { path: '', param: {} as CallWxyxtAppParams }) => {
  wxyxt = wxyxt || new CallApp(WXYKT_APP_CALL_SETTING)
  param = param || {}
  param.userId = `${param.userId ?? sessionStorage.getItem(USER_ID_KEY) ?? ''}`
  wxyxt.open({ path, param: param as Record<string, unknown> })
}
